<template>
  <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
    <el-select
        multiple
        v-model="pageInfo.shopAndMarketPlaceIds"
        collapse-tags
        style="margin-right: 10px;width:200px;margin-bottom:10px"
        @remove-tag='clearShop'
        filterable
        placeholder="选择店铺">
        <el-option
        v-for="item in tress"
        :key="item.id"
        :label="item.name"
        :value="item.id+'-'+item.marketPlaceId"
        @click.native="find('shop')"
        >
        </el-option>
    </el-select>
    <el-select
        collapse-tags
        style="margin-right: 10px;width:110px"
        clearable
        v-model="pageInfo.type"
        placeholder="全部类别">
        <el-option
        v-for="item in type"
        :key="item.id"
        @click.native="find"
        :label="item.value"
        :value="item.value">
        </el-option>
    </el-select>
    <el-select
        v-model="pageInfo.updateBy"
        multiple
        collapse-tags
        style="margin-right: 10px;width:190px"
        clearable
        filterable
        placeholder="全部操作人"
        @change="find">
        <el-option
        v-for="item in updateBy"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select
        v-model="pageInfo.createBy"
        multiple
        collapse-tags
        style="margin-right: 10px;width:190px"
        clearable
        @clear="clearableShop"
        filterable
        placeholder="全部创建人"
        @change="find">
        <el-option
        v-for="item in createBy"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <TimeQuantum @selectFinish="selectFinish" :clearble='true' section="自定义" @sectionFinish="sectionSure" style="margin-right:10px"></TimeQuantum>
    <el-input 
    v-model="pageInfo.keyword"
    placeholder="搜索模板名称"
    style="margin-right: 10px;width:130px" @keyup.enter.native = "find">
    </el-input>
    <el-button type="primary" @click="find">查询</el-button>
    <el-button @click="reset">重置</el-button><br>

    <el-button type="primary" @click="add">添加模板</el-button>
    <el-button @click="typeManage=true">类型管理</el-button>
    <el-button @click="batchDelete">批量删除</el-button>
    <el-table
        :data="tableData"
        tooltip-effect="dark"
        border
        cell-style="text-align:center;font-size:14px"
        header-cell-style='background:#FAFAFA;text-align:center;font-size:14px'
        style="width: 100%;margin-top:15px"
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        ></el-table-column>
        <el-table-column
            prop="type"
            label="类目" >
        </el-table-column>
        <el-table-column
            prop="name"
            label="模板名称" >
            <template slot-scope="scope">
                <span type="text">{{scope.row.name}}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="countryName"
            label="国家" >
        </el-table-column>
        <el-table-column
            prop="shopName"
            label="店铺" >
        </el-table-column>
        <el-table-column
            prop="createBy"
            label="创建人" >
        </el-table-column>
        <el-table-column
            prop="updateBy"
            label="操作人" >
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间" >
        </el-table-column>
        <el-table-column
            prop="prop"
            label="操作" >
            <template slot-scope="scope">
                <el-button type="text" @click="update(scope.row)">编辑</el-button>
                <el-button @click="delTemplate(scope.row.id)" type="text">删除</el-button>
                <el-button @click="copyAdd(scope.row)" type="text">复制新增</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="block" style="text-align:center;margin:15px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog
    width="40%"
    title="类型管理"
    :visible.sync="typeManage"
    append-to-body>
        <p style="margin-bottom:10px">所有类型</p>
        <div class="box" v-loading='buttonLoading'>
            <div :class="index<=1?'active type':'type'" v-for="(item,index) in type" :key="item.id" :disabled="index<2">
                <p v-if="index<=1">{{item.value}}</p>
                <input v-if="index>1" type="text" @focus="oldName=item.value" v-model="item.value" @change="typeChange(item.value)">
                <!-- 暂无删除功能 -->
                <!-- <i class="el-icon-close" v-show="index>1" @click="removeType(item.id)"></i> --> 
            </div>
            <!-- 新增功能 -->
            <!-- <div class="type" v-show="minshow">
                <input type="text" v-model="mintype.value" @blur="addType">
                <i class="el-icon-close" @click="minshow=false"></i>
            </div> -->
        </div>
        <!-- 暂不需要新增功能 -->
        <!-- <el-button type="primary" plain icon="el-icon-plus" size="mini" style="margin-top:10px" @click="append">添加类型</el-button> -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="typeManage = false">取 消</el-button>
            <el-button type="primary" @click="typeManage = false">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
        :title="title"
        :visible.sync="addTemplate"
        width="60%"
        :before-close="addTemplateClose">
        <div>
            <el-form ref="addInfo" :rules="addRulse" :model="addInfo" label-width="80px">
                <el-form-item label="店铺" prop="shopAndmar">
                    <el-select
                    :disabled="title=='编辑模板'?true:false"
                        v-model="addInfo.shopAndmar"
                        style="margin-right: 10px;width:190px;"
                        clearable
                        @clear='clearable'
                        filterable
                        placeholder="选择店铺">
                        <el-option
                        v-for="item in tress"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id+'-'+item.marketPlaceId"
                        @click.native="getMarketPlaceIds(item)"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板类目" prop="type">
                    <el-autocomplete
                    class="inline-input"
                    v-model="addInfo.type"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入模板类目"
                    @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="模板名称" prop="name">
                    <el-input v-model="addInfo.name" placeholder="请输入模板名称"></el-input>
                </el-form-item>
                <el-form-item label="模板内容" prop="content">
                    <div style="display:flex">
                        <el-input class="emojiInput" v-model="addInfo.content" :rows="20" style="width:75%" type='textarea' placeholder="请输入"></el-input>
                        <div style=" padding:8px;width:24%;display:height:100%;margin-left:15px;border:1px solid #DCDFE6;border-radius:4px">
                            <p style="margin-bottom:8px">插入变量</p>
                            <p  v-for="item in variable" :key="item.name">
                                <el-link type='primary' @click="inputTemplate(item)">{{item.name}}</el-link>
                                <el-tooltip placement="top" v-if="item.name=='买家昵称'">&nbsp;
                                    <div slot="content">该变量信息存在获取不到部分买家名称，请谨慎使用。</div>
                                    <span class="iconfont icon-wenhao"></span>
                                </el-tooltip>
                            </p>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer">
            <el-button @click="addTemplateClose">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum.vue'
import {pageInit,listPage,del,delBatch,addInit,mailTemplate,updateInit,updateTemplate,updateTypeName} from '@/api/Mail/messageTemplate.js'
export default {
    components:{
        TimeQuantum
    },
    data(){
        return {
            title:'',
            typeManage:false,
            addTemplate:false,
            tableData:[],
            buttonLoading:false,
            loading:false,
            total:'',
            createBy:[],
            tress:[],
            updateBy:[],
            type:[],
            ids:[],
            pageInfo:{
                type:'',
                current:'1',
                keyword:'',
                shopAndMarketPlaceIds:[],
                createBy:[],
                updateBy:'',
                beginTime:'',
                endTime:'',
                pageSize:''
            },
            variable:[],
            addInfo:{
                shopAndmar:'',
                content:'',
                marketPlaceId:'',
                name:'',
                shopId:'',
                type:''
            },
            addRulse:{
                shopAndmar:[{required:true,message:'请选择店铺',trigger:'change'}],
                name:[{required:true,message:'请输入模板名称',trigger:'blur'}],
                type:[{required:true,message:'请输入模板类目',trigger:'change'}],
                content:[{required:true,message:'请输入模板内容',trigger:'blur'}]
            },
            minshow:false,
            oldName:'',
            mintype:{id:'',value:''}
            
        };
    },
    created(){
        this.getPageInit('start')
        // this.getListPage()
    },
    methods:{
        typeChange(newName){
            if(newName==this.oldName) return
            this.$confirm('将修改类别名称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.buttonLoading=true
                console.log('dddddddd');
                updateTypeName({newType:newName,oldType:this.oldName}).then(res=>{
                    this.buttonLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        append(){
            this.minshow=true
            this.mintype={id:'',value:''}
        },
        querySearch(queryString,cb){
            var restaurants = this.type
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            console.log(results);
            cb(restaurants);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) == 0);
            };
        },
        clearShop(){
            console.log(this.pageInfo.shopAndMarketPlaceIds);
            if(this.pageInfo.shopAndMarketPlaceIds.length==0){
                this.tress.forEach(item=>{
                    if(item.isSelected==1){
                        this.pageInfo.shopAndMarketPlaceIds=[item.id+'-'+item.marketPlaceId]
                        this.pageInfo.current=1
                        this.getListPage()
                        this.$message.warning('已切换为默认店铺')
                    }
                })
            }
            this.pageInfo.current=1
            this.getListPage()
        },
        reset(){
            this.pageInfo={
                current:'1',
                keyword:'',
                shopIds:[],
                marketPlaceIds:[],
                createBy:[],
                updateBy:'',
                beginTime:'',
                endTime:'',
                pageSize:''
            }
            this.getListPage()
        },
        update(info){
            this.title='编辑模板'
            this.loading=true
            updateInit(info.id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.addTemplate=true
                    console.log(res);
                    this.addInfo={...res.data.data.mailTemplate,shopAndmar:info.shopId+'-'+info.marketPlaceId}
                    this.variable=res.data.data.variable
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        copyAdd(info){
            this.update(info)
            this.$nextTick(()=>{
                this.title='添加模板'
            })
        },
        addTemplateClose(){
            this.$refs.addInfo.resetFields()
            this.addTemplate=false
            delete this.addInfo.id
        },
        sure(){
            console.log(this.addInfo);
            this.$refs.addInfo.validate((valid) => {
                if (valid) {
                    this.buttonLoading=true
                    if(this.title=='添加模板'){
                        mailTemplate(this.addInfo).then(res=>{
                            this.buttonLoading=false
                            if(res.data.code==200){
                                this.$message.success(res.data.message)
                                this.addTemplateClose()
                                this.getListPage()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        })
                    }else{
                        updateTemplate(this.addInfo).then(res=>{
                            this.buttonLoading=false
                            if(res.data.code==200){
                                this.$message.success(res.data.message)
                                this.addTemplateClose()
                                this.getListPage()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
            
        },
        inputTemplate(info){
            // this.addInfo.content=this.addInfo.content+info.value
            var elInput = document.getElementsByClassName('el-textarea__inner')[0]

            var startPos = elInput.selectionStart
            var endPos = elInput.selectionEnd
            console.log(endPos);

            if (startPos === undefined || endPos === undefined) return
            var txt = elInput.value
            var result = txt.substring(0, startPos) + info.value + txt.substring(endPos)
            elInput.value = result
            elInput.focus()
            elInput.selectionStart = startPos + info.value.length
            elInput.selectionEnd = startPos + info.value.length
            this.addInfo.content = result
        },
        add(){
            this.title='添加模板'
            this.loading=true
            addInit().then(res=>{
                this.loading=false
                console.log(res);
                if(res.data.code==200){
                    this.addTemplate=true
                    this.variable=res.data.data.variable
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loading=false
            })
        }, 
        clearableShop(){
            this.pageInfo.shopIds=[]
            this.pageInfo.marketPlaceIds=[]
        },
        clearable(){
            this.addInfo.shopId=''
            this.addInfo.marketPlaceId=''
        },
        getMarketPlaceIds(info){
            this.addInfo.marketPlaceId=info.marketPlaceId
            this.addInfo.shopId=info.id
            console.log(this.addInfo);
        },
        batchDelete(){
            if(this.ids.length==0) return this.$message.warning('请勾选具体模板')
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                delBatch({ids:this.ids}).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: res.data.message
                        });
                        this.getListPage()
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.data.message
                        });
                    }
                }).catch(()=>{
                    this.loading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            delBatch({ids:this.ids}).then(res=>{

            })
        },
        handleSelectionChange(val){
            console.log(val);
            this.ids=val.map(item=>{
                return item.id
            })
        },
        find(info){
            if(info=='shop') this.getPageInit()
            this.pageInfo.current=1
            if(this.pageInfo.shopAndMarketPlaceIds.length==0){
                this.tress.forEach(item=>{
                    if(item.isSelected==1){
                        this.pageInfo.shopAndMarketPlaceIds=[item.id+'-'+item.marketPlaceId]
                        this.getListPage()
                        this.$message.warning('已切换为默认店铺')
                    }
                })
            }
            this.getListPage()
        },
        selectFinish(val){
            console.log(val);
            this.pageInfo.beginTime = val.value[0]
            this.pageInfo.endTime = val.value[1]
        },
        sectionSure(val){
            this.pageInfo.beginTime = val.value[0]
            this.pageInfo.endTime = val.value[1]
        },
        getPageInit(info){
            pageInit().then(res=>{
                console.log(res);
                this.createBy=res.data.data.createBy
                this.updateBy=res.data.data.updateBy
                this.type=res.data.data.type.map((item,index)=>{
                    const obj ={}
                    obj.value=item
                    obj.id=index
                    return obj
                })
                this.tress=res.data.data.tress
                this.tress.forEach(item=>{
                    if(item.isSelected==1){
                        if(this.pageInfo.shopAndMarketPlaceIds.length==0) this.pageInfo.shopAndMarketPlaceIds=[item.id+'-'+item.marketPlaceId]
                    }
                })
                if(info=='start') this.getListPage()
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        getListPage(){
            this.loading=true
            listPage(this.pageInfo).then(res=>{
                this.loading=false
                this.total=res.data.pageInfo.total
                this.tableData=res.data.values
                console.log(res);
            })
        },
        delTemplate(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                del(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: res.data.message
                        });
                        this.getListPage()
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.data.message
                        });
                    }
                }).catch(()=>{
                    this.loading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    }
}
</script>

<style scoped lang='scss'>
.type{
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    
    height: 30px;
    border-radius: 4px;
    border: 1px #E4E7ED solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    
    input{
        border: none;
        width: 90px;
        outline: none;
        background: transparent;
        font-size: 14px;
        text-align: center;
        color: #606266;
    }
    p{
        text-align: center;
        max-width: 300px;
    }
    i{
        color: gray;
    }
}
.box{
    display: flex;
    flex-wrap: wrap;
}
.active{
    background: #DCDFE6;
}
</style>